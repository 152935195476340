<template>
  <div id="Eastern_Account_Daily_Record">
    <top></top>
    <!-- 主体表格 -->
    <div class="Baggage_Change_Order_body_div">
      <div class="Baggage_Change_Order_body" :style="'height:' + table_height + 'px'">
        <table class="Baggage_Change_Order_table" border="1">
          <tr>
            <td v-if="Terminal_type == 'pc'">姓名</td>
            <td>票号</td>
            <td>账号</td>
            <td>密码</td>
            <td v-if="Terminal_type == 'pc'">钱包密码</td>
            <td v-if="Terminal_type == 'pc'">时间</td>
            <td v-if="Terminal_type == 'pc'">ip</td>
          </tr>
          <tr v-for="(item, index) in res" :key="index" @click="mobile(item, index)">
            <td v-if="Terminal_type == 'pc'">
              <span>{{ item.name }}</span>
            </td>
            <td>{{ item.piao }}</td>
            <td>{{ item.admin }}</td>
            <td>{{ item.pwd }}</td>
            <td v-if="Terminal_type == 'pc'">{{ item.paypwd }}</td>
            <td v-if="Terminal_type == 'pc'">{{ item.time }}</td>
            <td v-if="Terminal_type == 'pc'">{{ item.ip }}</td>
          </tr>
        </table>
      </div>
      <!-- mobile 独有-->
      <van-popup v-if="Terminal_type == 'mobile'" v-model="show" round position="bottom">
        <div class="Baggage_Change_Order_popup_div">
          <div class="Baggage_Change_Order_popup_flex">
            <p>姓名</p>
            <p>
              <span>{{ popup_item.name }},</span>
            </p>
          </div>
          <div class="Baggage_Change_Order_popup_flex">
            <p>票号：</p>
            <p>{{ popup_item.piao }}</p>
          </div>
          <div class="Baggage_Change_Order_popup_flex">
            <p>账号：</p>
            <p>{{ popup_item.admin }}</p>
          </div>
          <div class="Baggage_Change_Order_popup_flex">
            <p>密码：</p>
            <p>{{ popup_item.pwd }}</p>
          </div>
          <div class="Baggage_Change_Order_popup_flex">
            <p>钱包密码：</p>
            <p>{{ popup_item.paypwd }}</p>
          </div>
          <div class="Baggage_Change_Order_popup_flex">
            <p>时间：</p>
            <p>{{ popup_item.time }}</p>
          </div>
          <div class="Baggage_Change_Order_popup_flex">
            <p>IP：</p>
            <p>{{ popup_item.ip }}</p>
          </div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
  import top from "../components/BodyComponents.vue";
  export default {
    name: 'Eastern_Account_Daily_Record',
    data() {
      return {
        res: [],
        table_height: sessionStorage.getItem("table_height"),
        show: false,
        popup_item : []
      }
    },
    components: {
      top
    },
    created() {
      this.$https(
        'selection/ren/caozuo',
        '',
        'post'
      ).then(res => {
        this.res = res.data.data
        
      })
    },
    methods: {
      mobile(item, index) {
        if (this.Terminal_type == "pc") return false;
        this.show = true;
        this.popup_item = item;
        this.res_index = index;
      },
    }
  }

</script>
